







































































































































































































































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import useFormModel from "@/apps/core/modules/useFormModel";
import { SetoranMandiriFM } from "@/apps/tabungan/models/setoranMandiriForm";
import TagihanVM, { Tagihan } from "@/apps/tabungan/models/tagihan";
import { SetoranMandiri } from "../models/setoranMandiri";
import { listVM as setoranMandiriListVM } from "@/apps/tabungan/modules/stores/setoranMandiri";
import { listVM as setoranListVM } from "@/apps/tabungan/modules/stores/setoran";
import { listVM as pinjamanListVM } from "@/apps/pinjaman/modules/stores/pengajuan";
import UploadPhoto from "@/apps/core/modules/uploadPhoto";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "SetoranMandiriForm",
  components: {
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const requiredFields = ["bukti"];
    const nonRequiredFields = [
      "wajib",
      "sukarela",
      "wajibMonth",
      "sukarelaMonth",
      "nilaiSs",
      "cicilan",
      // "catatan",
    ];
    const form = new SetoranMandiriFM(requiredFields, nonRequiredFields);
    const composition = useFormModel(form);
    const setoran = composition.instance as SetoranMandiri;

    const tagihanVM = new TagihanVM();
    const viewModelRef = reactive(tagihanVM);
    const tagihan = viewModelRef.instance as Tagihan;
    const cicilan1Ref = reactive({});
    const cicilan2Ref = reactive({});
    const buktiDataRef = ref(null);

    onMounted(async () => {
      viewModelRef.isLoading = true;
      await viewModelRef.fetch();
      viewModelRef.isLoading = false;

      Object.assign(form.instance, viewModelRef.instance);

      if (tagihan.cicilan !== null && tagihan.cicilan.length > 0) {
        const cicilan1 = tagihan.cicilan[0];
        cicilan1.month = cicilan1.pinjaman.month;
        Object.assign(cicilan1Ref, cicilan1);
        if (tagihan.cicilan.length == 2) {
          const cicilan2 = tagihan.cicilan[1];
          cicilan2.month = cicilan1.pinjaman.month;
          Object.assign(cicilan2Ref, cicilan2);
        }
      }
      
      updateSukarela();
    });

    const updateWajib = (wajibMonth: number) => {
      const nilaiSw = tagihan.nilaiSw ?? 0;
      setoran.wajib = nilaiSw * wajibMonth;
    };

    const updateSukarela = () => {
      const nilaiSs = setoran.nilaiSs ?? 0;
      const sukarelaMonth = setoran.sukarelaMonth ?? 0;
      setoran.sukarela = nilaiSs * sukarelaMonth;
    };

    const saveSetoran = async (url: string | null) => {
      setoran.bukti = url;
      await form.save();
      setoranMandiriListVM.setRefreshRequest("setoranMandiri");
      setoranListVM.setRefreshRequest("setoran");
      pinjamanListVM.setRefreshRequest("pinjaman");
    };

    const saveForm = async () => {
      const uploadPhoto = new UploadPhoto("setoran", (uploading: boolean) => {
        form.isLoading = uploading;
      });
      uploadPhoto.requestAWS(buktiDataRef.value, saveSetoran);
      // await form.save(SaveContext.Create, false, false);
      // if (form.getIsValid().value) {
      //   const uploadPhoto = new UploadPhoto("setoran", (uploading: boolean) => {
      //     form.isLoading = uploading;
      //   });
      //   uploadPhoto.requestAWS(buktiDataRef.value, saveSetoran);

      // }
    };

    const validateBukti = () => {
      setoran.bukti = buktiDataRef.value;
      form.validate("bukti");
    };

    return {
      // Data
      buktiDataRef,
      cicilan1Ref,
      cicilan2Ref,
      initial: viewModelRef.instance,

      // Composition
      ...composition,

      // Method
      saveForm,
      updateSukarela,
      updateWajib,
      validateBukti,
    };
  },
});
