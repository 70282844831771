import { API } from "@/apps/core/api";
import { ToastProgrammatic as Toast } from "buefy";
import axios from "axios";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";


class Tagihan extends PlainModel {
  wajib: number | null = null;
  wajibMonth: number | null = null;
  nilaiSw: number | null = null;
  sukarela: number | null = null;
  sukarelaMonth: number | null = null;
  nilaiSs: number | null = null;
  total: number | null = null;
  cicilan: Array<Record<string, any>> | null = null;
}

class TagihanAPI extends API {
  static modelPath = "/tabungan/tagihan/";

  constructor() {
    super(TagihanAPI.modelPath);
  }
}

export default class TagihanVM extends ViewModel {
  constructor() {
    super(new TagihanAPI(), new Tagihan());
  }

  async fetch(): Promise<void> {
    try {
        const url = this.api.getAPIUrl();
        const response = await axios.get(url);
        this.instance.loadData(response.data);
    } catch (error) {
        Toast.open("Data gagal dimuat.");
    }
  }
}


export { Tagihan, TagihanAPI };