












































import { defineComponent } from "@vue/composition-api";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import { isNasabah } from "@/apps/accounts/modules/store";
import { listVM } from "@/apps/tabungan/modules/stores/setoranMandiri";

export default defineComponent({
  name: "SetoranMandiriList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    SetoranMandiriListBox: () => import("@/apps/tabungan/components/SetoranMandiriListBox.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const composition = useInfiniteScrollList(listVM);
    const classContent = isNasabah.value? "column no-padding is-full":"column is-full"
    return {
      // Data
      classContent,

      // Computed
      isNasabah,

      // Composition
      ...composition,
    };
  },
});
